import { styled } from 'styled-components';
import { device } from '../../../../utils/variables';

export default styled.div`
    @media ${device.lg} {
        flex: 1;
    }

    form {
        margin-top: 1rem;

        @media ${device.lg} {
            margin: 0;
        }
    }

    .save-btn {
        margin-top: 1rem;

        @media ${device.lg} {
            max-width: 200px;
        }
    }

    .ent-info {
        margin-bottom: 20px;
    }
`;
