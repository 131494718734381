import { Col, Form, Row, Select, Space, App, Typography, Alert } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react';
import Wrapper from './styles';
import { CreditCardOutlined } from '@ant-design/icons';
import { useTranslation } from '../../../../hooks/use-translation';
import { UiButton } from '../../../../ui/button/UiButton';
import { UiInput } from '../../../../ui/input/UiInput';
import { useForm } from 'antd/es/form/Form';
import axios from '../../../../utils/axios';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../store/store';
import { setProfile } from '../../../../store/slices/profileSlice';
import { Link, useNavigate } from 'react-router-dom';
import { supportedLocales } from '../../../navigator/Navigator';
import { getRoute } from '../../../../utils/browser';

const { Title } = Typography;

export function ProfileForm() {
    const { translate } = useTranslation();
    const [form] = useForm();
    const { message } = App.useApp();
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const navigate = useNavigate();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        form.setFieldsValue({
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
            occupation: userProfile?.occupation,
            description: userProfile?.description,
            phone: userProfile?.phone,
            workAddress: userProfile?.workAddress,
            locale: userProfile?.locale,
            entrepreneurAccount: userProfile?.entrepreneurAccount,
            entrepreneurName: userProfile?.entrepreneurName,
        });
    }

    async function updateProfile() {
        const values = form.getFieldsValue();
        try {
            await axios.post('/account/profile/edit', values);
            message.open({
                type: 'success',
                content: translate('Profile updated successfully', 'profile.edit'),
            });
            store.dispatch(setProfile(values));
            navigate(getRoute('profile'));
        } catch (err) {
            message.open({
                type: 'error',
                content: translate('Something went wrong while updating profile', 'profile.edit'),
            });
        }
    }

    return (
        <Wrapper>
            <Form form={form} layout="vertical">
                <Form.Item name="firstName" label={translate('First name', 'profile.edit')}>
                    <UiInput placeholder={translate('First name', 'profile.edit')} />
                </Form.Item>
                <Form.Item name="lastName" label={translate('Last name', 'profile.edit')}>
                    <UiInput placeholder={translate('Last name', 'profijle.edit')} />
                </Form.Item>
                <Form.Item name="occupation" label={translate('Occupation', 'profile.edit')}>
                    <UiInput placeholder={translate('Occupation', 'profile.edit')} />
                </Form.Item>
                <Row gutter={{ xs: 0, md: 20 }}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item name="phone" label={translate('Phone', 'profile.edit')}>
                            <UiInput placeholder={translate('Phone', 'profile.edit')} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item name="locale" label={translate('Language', 'profile.edit')}>
                            <Select options={supportedLocales.map((locale) => ({ label: locale, value: locale }))} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="workAddress" label={translate('Work address', 'profile.edit')}>
                    <UiInput placeholder={translate('Work address', 'profile.edit')} />
                </Form.Item>
                <Form.Item name="description" label={translate('About me', 'profile.edit')}>
                    <TextArea rows={5} placeholder={translate('About me', 'profile.edit')} />
                </Form.Item>
                <Space size={0} direction="vertical">
                    <Title level={3}>{translate('Entrepreneur information', 'profile.edit')}:</Title>
                    <Alert
                        type="info"
                        message={translate('This information will be used only for withdrawal', 'profile.edit')}
                        className={'ent-info'}
                    />
                    <Form.Item name="entrepreneurName" label={translate('Name', 'profile.edit')}>
                        <UiInput placeholder={translate('Name', 'profile.edit')} />
                    </Form.Item>
                    <Form.Item name="entrepreneurAccount" label={translate('Account', 'profile.edit')}>
                        <UiInput placeholder={translate('Account', 'profile.edit')} />
                    </Form.Item>
                    <Link to="https://www.lhv.ee/en/entrepreneur-account#" target="_blank">
                        <CreditCardOutlined /> {translate('Register an LHV Pank entrepreneur account', 'profile.edit')}
                    </Link>
                </Space>
            </Form>
            <UiButton className="save-btn" type="success" onClick={() => updateProfile()}>
                {translate('Save', 'profile.edit')}
            </UiButton>
        </Wrapper>
    );
}
